var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('base-form',{attrs:{"model":"Employee","slug":"sysop_employee","title":_vm.title,"metadata":_vm.metadata,"uid":_vm.employee.id,"change":_vm.change,"hide-details":!_vm.showDetail},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mb-2",attrs:{"align":"center","justify":"center","no-gutters":""}},[(_vm.displayName)?_c('v-chip',{attrs:{"outlined":"","large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.employee.person.name)+" ")]):_vm._e()],1),(!_vm.employee.id)?_c('v-text-field',{attrs:{"label":"Rut","hint":"Rut debe tener minimo 8 caracteres","required":"","rules":[..._vm.rutRules,..._vm.fieldRequired],"g-mask":{
          mask: '########-C',
          tokens: {
            '#': { pattern: /[0-9]/ },
            'C': { pattern: /./ }
          },
          kind:'both'
        }},on:{"blur":function($event){return _vm.validateRut(_vm.formData.rut)}},model:{value:(_vm.formData.rut),callback:function ($$v) {_vm.$set(_vm.formData, "rut", $$v)},expression:"formData.rut"}}):_vm._e(),(!_vm.employee.id)?_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"pl-0 pr-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"1er Nombre","required":"","rules":_vm.fieldRequired},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})],1),_c('v-col',{staticClass:"pr-0 pl-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"2do Nombre"},model:{value:(_vm.formData.secondName),callback:function ($$v) {_vm.$set(_vm.formData, "secondName", $$v)},expression:"formData.secondName"}})],1)],1):_vm._e(),(!_vm.employee.id)?_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"pl-0 pr-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"1er Apellido","required":"","rules":_vm.fieldRequired},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})],1),_c('v-col',{staticClass:"pr-0 pl-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"2do Apellido"},model:{value:(_vm.formData.secondLastName),callback:function ($$v) {_vm.$set(_vm.formData, "secondLastName", $$v)},expression:"formData.secondLastName"}})],1)],1):_vm._e(),(!_vm.employee.id)?_c('v-text-field',{attrs:{"label":"Telefono laboral","required":"","rules":_vm.fieldRequired,"g-mask":{
          mask: '+############',
          tokens: {
            '#': { pattern: /[0-9]/ },
          },
          kind:'both'
        }},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}):_vm._e(),(!_vm.employee.id)?_c('v-text-field',{attrs:{"label":"Email laboral","required":"","rules":[..._vm.fieldRequired,..._vm.emailValidate]},on:{"blur":function($event){return _vm.checkEmail(_vm.formData.email)}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}):_vm._e(),_c('g-files',_vm._b({attrs:{"label":"Foto de integrante","id-process":_vm.idProcess},model:{value:(_vm.formData.photo),callback:function ($$v) {_vm.$set(_vm.formData, "photo", $$v)},expression:"formData.photo"}},'g-files',_vm.fields.photo.properties,false)),_c('p',[_vm._v("Selecciona los roles")]),(_vm.displayError)?_c('p',{staticClass:"red--text"},[_vm._v(" Debe seleccionar a lo menos un rol ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.systemRoles),function(rol){return _c('v-row',{key:rol.id,attrs:{"align":"center","justify":"start","no-gutters":""}},[_c('v-checkbox',{attrs:{"label":rol.description,"value":rol.slug,"error":_vm.displayError,"hide-details":""},model:{value:(_vm.formData.roles),callback:function ($$v) {_vm.$set(_vm.formData, "roles", $$v)},expression:"formData.roles"}})],1)}),1)],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('v-row',{staticClass:"pb-2 px-3",attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-btn',{staticClass:"text-capitalize text--text mr-3",attrs:{"color":"red"},on:{"click":_vm.closeForm}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"px-8",attrs:{"color":"primary","loading":_vm.loadingForm || _vm.isUploadingFile},on:{"click":_vm.send}},[_vm._v(" Enviar ")])],1)]},proxy:true},{key:"alerts",fn:function(){return [_c('g-alert',{attrs:{"open":_vm.alert.open,"title":_vm.alert.title,"subtitle":_vm.alert.subtitle},on:{"confirm":_vm.confirmSetRutData,"cancel":_vm.closeRutInfo}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }